<messages>["./Contact"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <base-layout mw1>
    <v-col cols="12">
      <!-- "contact does not exist" alert -->
      <v-alert
        type="error"
        :value="!isLoading && !contact">
        {{
          $t (
            `view.invalidContact.${vid ? 'byId' : 'byHandle'}`,
            {handle, registry, vid})
        }}
      </v-alert>

      <!-- contact details -->
      <v-card v-if="contact">
        <v-card-title primary-title class="py-0">
          <div>
            <div class="text-h5">
              <span
                :class="{deletedItemHeadline: contact.currentVersionDeleted}"
                v-text="contactHandle"/>
            </div>
            <div class="cgwng-subheading">
              <registry-type-name :value="contact.registryId" cut-tlds :max-tlds="5"/>
            </div>
          </div>
        </v-card-title>
        <v-tabs
          v-model="activeTab"
          right
          slider-color="primary">
          <!-- current contact data -->
          <v-tab
            key="current"
            v-t="'general.history.current'"
            :disabled="contact.currentVersionDeleted"/>
          <v-tab-item key="current">
            <v-card-text v-if="contact" class="pt-0">
              <contact-data :value="contact">
                <template
                  v-if="isInquirable"
                  #controls>
                  <i18n :path="`view.includeRemote.${includeRemote}.text`">
                    <template #link>
                      <a
                        v-t="`view.includeRemote.${includeRemote}.link`"
                        @click="includeRemote = !includeRemote"/>
                    </template>
                  </i18n>
                </template>
              </contact-data>
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <back-button/>
              <template v-if="isManageable">
                <v-btn
                  v-if="isSyncable"
                  v-t="'general.button.sync'"
                  @click="syncContact"/>
                <v-btn
                  v-t="'general.button.manageStates'"
                  :disabled="isStatusModifyDisabled"
                  @click="statesDialog=true"/>
                <v-btn
                  v-t="'general.button.edit'"
                  :disabled="isModifyDisabled"
                  :to="{name: 'contact.edit', params: {
                    registry: contact.registryId,
                    handle: contactHandle
                  }}"
                  color="primary"/>
              </template>
            </v-card-actions>
            <states-dialog
              v-if="contact.id"
              v-model="statesDialog"
              type="contact"
              :object="{id: contact.id, name: contact.handle}"
              @success="loadContactByProps"/>
          </v-tab-item>

          <!-- historic contact data -->
          <v-tab
            key="historic"
            v-t="'general.history.historic'"/>
          <v-tab-item key="historic">
            <v-card-text class="pt-2">
              <history-panel
                v-for="(data, idx) in historicObjectData"
                :id="'version-' + data.versionId"
                :key="data.versionId"
                :expanded="isHistoryPanelExpanded (idx)"
                :seq-number="idx"
                @expand="assignHistoricContact (idx)">
                <template #label>
                  <span v-text="idx + 1"/>
                </template>
                <template #start>
                  <span v-text="formatDate (data.from)"/>
                </template>
                <template #end>
                  <span v-text="data.to ? formatDate (data.to) : $t ('general.history.now')"/>
                </template>
                <template #operation>
                  <order-type :name="data.orderType"/>
                </template>
                <template #content>
                  <contact-data
                    v-if="!data.isLoading && data.contact"
                    history
                    class="ma-0"
                    :value="data.contact"/>
                  <v-progress-linear
                    v-else
                    indeterminate
                    color="primary"/>
                </template>
              </history-panel>
            </v-card-text>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-col>
  </base-layout>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {mapGetters, mapMutations, mapActions} from 'vuex'

  import BackButton from '@/app/core/components/BackButton'
  import BaseLayout from '@/app/core/components/BaseLayout'
  import HistoryPanel from '@/app/core/components/HistoryPanel'
  import OrderType from '@/app/core/components/OrderType'
  import RegistryTypeName from '@/app/core/components/RegistryTypeName'

  import StatesDialog, {isUpdateProhibited}
    from '@/app/core/components/RegistryObject/StatesDialog'

  import RegistryCommands from '@/app/core/mixins/RegistryCommands'

  import RegistryObjectView, {HISTORIC}
    from '@/app/core/mixins/RegistryObjectViewHelper'

  import ContactData from './ContactData'

  export default {
    name: 'ContactView',

    components: {
      BackButton,
      BaseLayout,
      ContactData,
      HistoryPanel,
      OrderType,
      RegistryTypeName,
      StatesDialog
    },

    mixins: [RegistryCommands, RegistryObjectView],

    props: {
      handle: {
        type: String,
        default: undefined
      },
      registry: {
        type: String,
        default: undefined
      }
    },

    data () {
      return {
        isLoading: true,
        includeRemote: false,
        contact: undefined,
        registryMetaData: {},
        statesDialog: false
      }
    },

    computed: {
      ...mapGetters ({
        mayManageObject: 'auth/mayManageObject',
        permissions: 'auth/permissions'
      }),

      loadParams () {
        return {
          id: this.id,
          vid: this.vid,
          registryType: this.registry,
          handle: this.handle,
          includeRemote: this.includeRemote
        }
      },

      contactHandle () {
        return this.handle || this.contact.handle
      },

      isModifyDisabled () {
        return this.isUpdateProhibited (this.contact?.states) ||
          !this.supports (this.registry, 'ContactModify')
      },

      isStatusModifyDisabled () {
        return !this.supports (this.registry, 'ContactStatusModify')
      },

      isAdmin () {
        return this.permissions.includes ('ManageAllObjects')
      },

      isThinRegistry () {
        return this.registryMetaData.thinRegistry
      },

      isInquirable () {
        return this.contact && !this.contact.deleted &&
          !this.isThinRegistry
      },

      isManageable () {
        return this.mayManageObject (this.contact.clientId)
      },

      isSyncable () {
        return this.isInquirable && this.isAdmin
      }
    },

    watch: {
      loadParams () {
        this.loadContactByProps ()
      },

      'contact.id' (newValue) {
        if (newValue) {
          this.loadContactHistoryData ()
        }
      },

      'contact.registryId' (newValue) {
        if (newValue) {
          this.loadRegistryMetaData ()
        }
      }
    },

    created () {
      this.loadContactByProps ()
    },

    methods: {
      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess'
      }),
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      isUpdateProhibited,

      /**
       * Load the contact with the given parameters.
       *
       * @param {Object} params     the contact load parameters
       */
      loadContact (params) {
        this.isLoading = true
        const op = params.vid ? 'contact/loadVersion' : 'contact/load'

        this.fetchData ({
          op,
          params: {
            ...params,
            purpose: 'view'
          },
          cb: data => {
            this.contact = {
              ...data.contactData,
              ...data.viewData,
              wapVerified: data.wapVerified
            }

            this.setObjectId (this.contact.id)

            if (params.vid || this.contact.currentVersionDeleted) {
              this.setObjectVersionId (this.contact.versionId)
              this.setActiveTab (HISTORIC)
            }
          },
          cbFinal: ({success}) => {
            if (!success) {
              // if registry data was requested and could not be retrieved
              // (error), then try to load DB version, otherwise do nothing
              // (`this.includeRemote` is already `false` )
              this.includeRemote = false
            }

            this.isLoading = false
          }
        })
      },

      /**
       * Assign the data of the contact version specified by the given contact
       * data object to the contact data object.
       *
       * @param {Integer} index          expanded item index
       */
      assignHistoricContact (index) {
        const contactData = this.historicObjectData[index]
        this.setObjectVersionId (contactData.versionId)

        if (index === 0) {
          contactData.contact = {
            ...this.contact,
            ...this.viewData
          }
        } else if (!contactData.contact) {
          contactData.isLoading = true
          this.fetchData ({
            op: 'contact/loadVersion',
            params: {
              vid: contactData.versionId,
              purpose: 'view'
            },
            cb: data => {
              contactData.contact = {
                ...data.contactData,
                ...data.viewData
              }
            },
            cbFinal: () => {
              contactData.isLoading = false
              this.navigateToCurrentVersion ()
            }
          })
        }
        this.navigateToCurrentVersion ()
      },

      /**
       * Load the historic data of a contact.
       */
      loadContactHistoryData () {
        return this.fetchData ({
          op: 'contact/history',
          params: {
            id: this.contact.id
          },
          cb: data => {
            this.historicObjectData = data.historyData.map (item => ({
              ...item,
              isLoading: false,
              contact: null
            }))

            this.expandHistoryPanelWithLoadedVersion ()
          },
          cbFinal: () => {
            this.isLoading = false
          }
        })
      },

      /**
       * Load the contact specified by component properties.
       */
      loadContactByProps () {
        this.loadContact (this.loadParams)
      },

      /**
       * Load the registry-specific meta data for the contact registry
       */
      loadRegistryMetaData () {
        this.fetchData ({
          op: 'registry/loadMetaData',
          params: {
            registryId: this.contact.registryId
          },
          cb: data => {
            this.registryMetadata = data.registryMetaData
          }
        })
      },

      /**
       * Synchronize the contact with the registry
       */
      syncContact () {
        this.fetchData ({
          op: 'contact/sync',
          params: {
            ids: [this.contact.versionId]
          },
          cb: data => {
            this.displaySuccessMessage (this.$tc ('sync.success'))

            if (data.ids[this.contact.versionId].startsWith ('deleted')) {
              this.$router.push ({name: 'contact.search'})
            } else {
              this.contact = undefined
              this.historicObjectData = undefined
              this.loadContactByProps ()
            }
          }
        })
      }
    }
  }
</script>
