<messages>["../Contact"]</messages>

<template>
  <v-row class="fill-height px-2" justify="space-between" align="center">
    <div>
      {{ $t (`label.wapStatus.${status}`) }}
      <v-icon
        v-if="status"
        color="green darken-3">
        check
      </v-icon>
      <v-icon
        v-else
        color="red darken-3">
        cancel
      </v-icon>
    </div>

    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-btn
          icon
          small
          :to="editPage"
          v-on="on"
          @click="$emit('click')">
          <v-icon>
            info
          </v-icon>
        </v-btn>
      </template>
      <span v-t="'label.wapStatus.info'"/>
    </v-tooltip>
  </v-row>
</template>

<script>
  export default {
    props: {
      status: {
        type: Boolean,
        required: true
      },
      email: {
        type: String,
        required: true
      }
    },

    computed: {
      editPage () {
        return {
          name: 'service.emailverification',
          query: {
            email: this.email
          }
        }
      }
    }
  }
</script>
