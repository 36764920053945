<messages>["@/app/i18n/common/GenericObject", "./Contact"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <v-row justify="space-between">
    <v-col cols="12" class="py-0">
      <v-row>
        <v-col class="pb-0" align-self="center">
          <slot name="controls">
            <v-spacer/>
          </slot>
        </v-col>
        <v-col class="justify-end shrink pb-0" align-self="center">
          <!-- "show order" button -->
          <order-dialog
            :order-id="value.orderId"
            :object-id="value.handle"
            type="contact">
            <template #activator="{ on }">
              <v-btn
                small
                class="ma-0 my-2 elevation-0"
                v-on="on">
                {{ history ? $t ('general.button.showOrder') :
                  $t ('general.button.showCurrentOrder') }}
              </v-btn>
            </template>
          </order-dialog>
        </v-col>
      </v-row>
    </v-col>

    <!-- contact data sections -->
    <v-col
      v-for="section in sections.filter (s => s.show)"
      :key="section.l"
      class="d-flex child-flex"
      cols="12" md="6">
      <data-card :title="$t (`view.section.${section.l}`)">
        <name-value-block :items="section.i"/>
      </data-card>
    </v-col>
  </v-row>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {EmptyMark, toCamelCase} from '@/app/utils/string'

  import ClientLink from '@/app/core/components/ClientLink'
  import NameValueBlock from '@/app/core/components/NameValueBlock'
  import RegistryTypeLink from '@/app/core/components/RegistryTypeLink'
  import OrderDialog from '@/app/core/components/Order/OrderDialog'
  import SecretText from '@/app/core/components/SecretText'
  import DataCard from '@/app/core/components/DataCard'

  import {byRegistryContact as getDomainSearchFilterByContact}
    from '@/app/pages/Domain/components/DomainFilter'

  import {CONTACT_STATES}
    from '@/app/core/components/RegistryObject/StatesDialog'

  import EmailStatusInfo from './components/EmailStatusInfo.vue'

  const statesFlat = [
    ...CONTACT_STATES.client,
    ...CONTACT_STATES.extended
  ]

  export default {
    name: 'ContactData',

    components: {
      NameValueBlock,
      OrderDialog,
      DataCard
    },

    props: {
      value: {
        type: Object,
        required: true
      },
      history: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      domainSearchLink () {
        return {
          name: 'domain.search',
          query: {
            filter: JSON.stringify (
              getDomainSearchFilterByContact (
                this.value.registryId, this.value.handle))
          }
        }
      },

      sections () {
        return [
          {l: 'basic', i: this.basicData, show: true},
          {l: 'dates', i: this.dates, show: true},
          {
            l: 'restrictedVariant',
            i: this.restrictedVariant,
            show: this.restrictedVariant.length
          },
          {
            l: 'extendedVariant',
            i: this.extendedVariant,
            show: this.extendedVariant.length
          },
          {l: 'states', i: this.states, show: this.states.length},
          {l: 'addOn', i: this.addOn, show: this.addOn.length > 0},
          {
            l: 'registrySpecific',
            i: this.registrySpecific,
            show: this.registrySpecific.length > 0
          }
        ]
      },

      basicData () {
        return [
          {
            name: this.$t ('state.label'),
            value: this.$t (`state.${this.value.deleted
              ? 'deleted'
              : 'active'}`)
          },
          {
            name: this.$t ('label.registryType'),
            value: true,
            slotValue: this.$createElement (RegistryTypeLink, {
              props: {
                value: this.value.registryId,
                short: true
              }
            })
          },
          {
            name: this.$t ('label.associatedDomains'),
            value: this.$t ('label.clickToSearch'),
            link: this.domainSearchLink
          },
          ...[
            '',
            'creator',
            'updater'
          ].map (type => {
            const memberId = type
              ? this.value[toCamelCase (type, 'memberId')]
              : this.value.clientId

            return {
              name: this.$t (`label.${toCamelCase (type, 'client')}`),
              value: !!memberId || EmptyMark,
              ...(
                memberId
                  ? {
                    slotValue: this.$createElement (ClientLink, {
                      props: {
                        id: memberId
                      }
                    })
                  }
                  : {}
              )
            }
          }),
          {
            name: this.$t ('label.maintainer'),
            value: this.value.maintainer || EmptyMark
          },
          {
            name: this.$t ('label.emailAddress'),
            value: this.value.emailAddress
          },
          ...this.value.wapVerified !== null && this.value.wapVerified !== undefined
            ? [{
              name: this.$t ('label.wapStatus.status'),
              value: true,
              slotValue: this.$createElement (EmailStatusInfo, {
                props: {
                  status: this.value.wapVerified,
                  email: this.value.emailAddress
                }
              })
            }]
            : [],
          ...[
            'faxNumber',
            'faxNumberExt',
            'language',
            'phoneNumber',
            'phoneNumberExt'
          ].map (this.propMapper (this.value)),
          ...(
            this.value.authInfo
              ? [{
                name: this.$t ('label.authInfo'),
                value: true,
                slotValue: this.$createElement (SecretText, {
                  props: {
                    value: this.value.authInfo
                  }
                })
              }]
              : []
          )
        ]
      },

      dates () {
        return [
          'creationDate',
          'updateDate',
          'transferDate'
        ].map (this.propMapper (this.value, v =>
          v ? this.formatDate (v) : EmptyMark))
      },

      restrictedVariant () {
        const address = this.getAddress ()
        return address.inUse ? this.prepareVariant (address) : []
      },

      extendedVariant () {
        const address = this.getAddress ('loc')
        return address.inUse ? this.prepareVariant (address) : []
      },

      states () {
        let states = []

        if (this.value?.states?.length) {
          states = [...this.value.states].sort ((a, b) =>
            statesFlat.indexOf (a) - statesFlat.indexOf (b)).map (it =>
            ({name: it, value: ' '}))
        }

        return states
      },

      addOn () {
        const addOnData = []
        const fields = this.value.addOnFields

        for (const field in fields) {
          if (fields.hasOwnProperty (field)) {
            addOnData.push ({
              name: 'addon' + field,
              value: fields[field]
            })
          }
        }

        return addOnData
      },

      registrySpecific () {
        const registrySpecific = []
        const fields = this.value.specificFields

        for (const field in fields) {
          if (fields.hasOwnProperty (field)) {
            registrySpecific.push ({
              name: field,
              value: fields[field]
            })
          }
        }

        return registrySpecific
      }
    },

    methods: {
      /**
       * get the contact address of specified type ('int' by default)
       *
       * @param type        the address type to be used
       * @return            the address object of specified type
       */
      getAddress (type = 'int') {
        const addresses = this.value ? this.value.addresses : undefined

        return addresses ? addresses[type] : undefined
      },

      /**
       * mapper for some object property name to object, which can be used as
       * argument for name-value component
       *
       * @param o         object, which property should be mapped
       * @param valCb     value callback function (possibility to transform
       *                  actual value, e.g. format the value)
       *
       * @return {function(*): {name: *, value: *}}
       */
      propMapper (o, valCb = v =>
        v || (typeof v === 'boolean' ? v : EmptyMark)) {
        return p => (
          {
            name: this.$t (`label.${p}`),
            value: valCb (o[p])
          }
        )
      },

      /**
       * prepare contact data variant properties as array of name-value pairs
       *
       * @param variant     contact data variant to be processed
       * @return {[{name: *, value: *}]}    variant property array
       */
      prepareVariant (variant) {
        return variant
          ? [
            ...[
              'title',
              'name',
              'organization'
            ].map (this.propMapper (variant)),
            ...(
              variant.street?.length
                ? [{
                  name: this.$t ('label.streets'),
                  value: true,
                  slotValue: variant.street.map ((s, i) =>
                    this.$createElement ('div', {
                      attrs: {
                        class: `street street${i}`
                      }
                    }, s))
                }]
                : []
            ),
            ...[
              'postalCode',
              'city',
              'stateOrProvince',
              'countryCode'
            ].map (this.propMapper (variant))
          ]
          : []
      }
    }
  }
</script>
